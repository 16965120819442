import React from 'react';
import './App.css';
import { Typography } from '@mui/material';
import { Box } from '@mui/material';

function App() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Typography variant="h1">Go backroad.</Typography>
    </Box>
  );
}

export default App;
